import React, { Component } from "react";
import Lottie from "lottie-react-web";

const ServiceList = [
  {
    icon: "02",
    title: "Web Development",
    description:
      "We develop High-performance websites utilizing rapid application frameworks, that are aligned to strategic business objectives.",
  },
  {
    icon: "01",
    title: "Graphic Design",
    description:
      "Transcend limits with creative designs for your brand with the wide range of designs and illustrations we offer.",
  },
  {
    icon: "04",
    title: "Business Analytics",
    description:
      "Why don’t you check out how we give hand in to fix solutions to meet your business goals by framework-ing in orderly fashion?",
  },
  {
    icon: "03",
    title: "Data Analytics",
    description:
      "We bring off practical trends and conclusions on your info. How about fetching it in to see what your data has in?",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Services",
      description =
        "Can't wait already to know the services we provide for our clients? Here.. let us show a sneak of what we are capable of.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">
                {/* <div className="service-btn"> */}
                <a
                  className="btn-transparent rn-btn-dark"
                  href="/service"
                  style={{ fontSize: "60px", fontWeight: "700" }}
                >
                  {title}
                </a>
                {/* </div> */}
              </h2>
              <p>{description}</p>
              <div className="service-btn">
                <a
                  className="btn-transparent rn-btn-dark"
                  href="/request-service"
                >
                  <span className="text">Request Custom Service</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <a href="/service">
                    <div className="service service__style--2">
                      <div className="icon">
                        <Lottie
                          style={{ margin: "0px 0px" }}
                          options={{
                            animationData: require(`../../../public/assets/json/lottie/icon-${val.icon}.json`),
                            loop: true,
                          }}
                          speed="0.6"
                          width="80px"
                          height="80px"
                        />
                      </div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
