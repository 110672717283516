const BlogContent = [
  {
    images: "01",
    title: "Coming Soon",
    category: "Development",
  },
  {
    images: "02",
    title: "Coming Soon",
    category: "Management",
  },

  {
    images: "03",
    title: "Coming Soon",
    category: "Design",
  },
  {
    images: "04",
    title: "Coming Soon",
    category: "Development",
  },
  {
    images: "01",
    title: "Coming Soon",
    category: "Management",
  },
  {
    images: "02",
    title: "Coming Soon",
    category: "Design",
  },
];

export default BlogContent;
