// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";

// Home layout
import Demo from "./dark/PortfolioLanding";

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";
import RequestForm from "./blocks/RequestForm";
import ContactForm from "./blocks/ContactForm";
import ComingSoon from "./elements/ComingSoon";

// Blocks Layout

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={DarkMainDemo}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/main-demo`}
            component={Demo}
          />

          {/* Element Layot */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service`}
            component={Service}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service-details`}
            component={ServiceDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-details`}
            component={PortfolioDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog`}
            component={Blog}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details`}
            component={BlogDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/request-service`}
            component={RequestForm}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/join-us`}
            component={ContactForm}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/coming-soon`}
            component={ComingSoon}
          />
          {/* Blocks Elements  */}

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorkerRegistration.register();
reportWebVitals();
