import React, { Component } from "react";
import Lottie from "lottie-react-web";

const ServiceList = [
  {
    icon: "02",
    title: "Web Development",
    description:
      "It may look like a Herculean task to build an app, but for us, it is a sandcastle. We build apps for you with the sleekiest UI and the in-demand tech.",
  },
  {
    icon: "03",
    title: "Data Science & Analytics",
    description:
      "TA-DA! We also analyze your DA-TA. We provide real time analytics for your brand. Because data can give new insights when shuffled, just like TA-DA and DA-TA.",
  },
  {
    icon: "01",
    title: "Graphic Design",
    description:
      "We create visual concepts that inspire your brand that can be used for your marketing and promotional campaigns.",
  },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  <Lottie
                    style={{ margin: "0px 0px" }}
                    options={{
                      animationData: require(`../../../public/assets/json/lottie/icon-${val.icon}.json`),
                      loop: true,
                    }}
                    speed="0.6"
                    width="150px"
                    height="150px"
                  />
                  {/* <img
                    src={`/assets/images/icons/icon-${val.icon}.png`}
                    alt="Digital Agency"
                    width={"150px"}
                    className="static"
                  /> */}
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p style={{ textAlign: "justify" }}>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
