import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class About extends Component {
  render() {
    let title = "About",
      description =
        "CADRA is the crew of the Industry 4.0 bringing out the impeccable solutions to the modern world with effective use of Blockchain tech. Our core competency lies in our ability to synergize software to facilitate the needs of the growing tech world with our ability of outsourcing.";
    return (
      <React.Fragment>
        <div className="active-dark">
          <PageHelmet pageTitle="About | CADRA" />
          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />
          {/* Start Breadcrump Area */}
          <Breadcrumb title={"About"} />
          {/* End Breadcrump Area */}

          {/* Start About Area  */}
          <div className="rn-about-area ptb--120 bg_color--7">
            <div className="rn-about-wrapper">
              <div className="container">
                <div className="row row--35 align-items-center">
                  <div className="col-lg-5">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/about/about-3.gif"
                        alt="About Images"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title">{title}</h2>
                        <p className="description">{description}</p>
                      </div>
                      <div className="row mt--30">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list">
                            <h3 className="title">Vision</h3>
                            <p style={{ textAlign: "justify" }}>
                              We aspire to improve our world and everyone’s
                              lives with the adoption and use of blockchain and
                              data science technologies.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="about-us-list">
                            <h3 className="title">Mission</h3>
                            <p style={{ textAlign: "justify" }}>
                              Create and foster a culture that promotes
                              innovative thinking, growth and opportunity for
                              our people with effective use of software
                              development and data science.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End About Area  */}

          {/* Start CounterUp Area */}
          <div className="rn-counterup-area pb--120 bg_color--7">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="fontWeight500">Our Fun Facts</h3>
                  </div>
                </div>
              </div>
              <CounterOne />
            </div>
          </div>
          {/* End CounterUp Area */}

          {/* Start Finding Us Area  */}
          <div
            className="rn-finding-us-area rn-finding-us bg_color--7"
            style={{ marginBottom: "50px" }}
          >
            <div className="inner">
              <div className="content-wrapper">
                <div className="content">
                  <h4 className="theme-gradient">
                    Meet our Doppelganger: cadra
                  </h4>
                  <p>
                    But we are in no way related! Cadra moths feed on raisins
                    and we feed you on risings. Still, wonder why is it on our
                    webpage? Just to see you smile! :) Join us to keep smiling!
                  </p>
                  <a className="rn-btn btn-white" href="/join-us">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="thumbnail">
                <div className="image">
                  <img
                    src="/assets/images/about/finding-us-01.png"
                    alt="Finding Images"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* End Finding Us Area  */}

          {/* Start Team Area  */}
          <div className="rn-team-area bg_color--1 ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title service-style--3 text-center mb--25">
                    <h2 className="title">
                      About our <span style={{ color: "#00ffff" }}>CREW</span>
                    </h2>
                    <p style={{ fontSize: "24px", lineHeight: "36px" }}>
                      Our team is well-versed in Web Development, Graphic Design
                      and Data Science, with a team of brilliant people. We can
                      tackle a wide range of projects from handling complex
                      data, to developing purpose-built applications.
                      <br /> <br />
                      Thanks to our diverse skill set, we can support you with
                      outsourcing. We can help you flesh out your product idea,
                      test its viability, devise and execute implementation
                      strategies and provide post-launch support. <br /> <br />
                      Being experts in software development, we're not bound to
                      any one chain or framework. This gives us the flexibility
                      to always use the best tool for a particular job. We also
                      specialize in cross-chain technologies by combining and
                      data science and decentralized applications
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Start Single Team  */}
                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-01.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Jone Due</h4>
                    <p className="designation">Sr. Web Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
                {/* End Single Team  */}

                {/* Start Single Team  */}
                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-02.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">BM. Alamin</h4>
                    <p className="designation">Sr. Web Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
                {/* End Single Team  */}

                {/* Start Single Team  */}
                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/team-03.jpg"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Jone Due</h4>
                    <p className="designation">Sr. Web Developer</p>
                  </div>
                  <ul className="social-icon">
                    <li>
                      <a href="https://www.facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="http://linkedin.com/">
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
                {/* End Single Team  */}
              </div>
            </div>
          </div>
          {/* End Team Area  */}

          {/* Start Testimonial Area */}
          {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div> */}
          {/* End Testimonial Area */}

          {/* Start Brand Area */}
          {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div> */}
          {/* End Brand Area */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default About;
