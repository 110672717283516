import React, { Component } from "react";

class About extends Component {
  render() {
    let title = "About",
      description =
        "CADRA is the crew of the Industry 4.0 bringing out the impeccable solutions to the modern world with effective use of Blockchain tech. Our core competency lies in our ability to synergize software to facilitate the needs of the growing tech world with our ability of outsourcing.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-1.gif"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12" style={{ marginTop: "40px" }}>
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">
                      {/* <div className="service-btn"> */}
                      <a
                        className="btn-transparent rn-btn-dark"
                        href="/about"
                        style={{ fontSize: "60px", fontWeight: "700" }}
                      >
                        {title}
                      </a>
                      {/* </div> */}
                    </h2>
                    <p className="description" style={{ textAlign: "justify" }}>
                      {description}
                    </p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p style={{ textAlign: "justify" }}>
                          CADRA is a student start-up firm built with core
                          values to build a interplanetary tech world. We love
                          to play a role in building a better working world for
                          our people, for our clients and for our communities.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">What we do</h3>
                        <p style={{ textAlign: "justify" }}>
                          Our brand for all and all for brand. Despite the glowy
                          industrial revolution 2.0, not all are gifted with the
                          time and talent to operate and manage these tech.
                          Hence, Cadra believes that technology should be
                          available for all and we stand for the people who
                          needs technology and assistance in using it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
