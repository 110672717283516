import React, { Component } from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaWhatsapp,
  FaInstagram,
  FaEnvelope,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/cadratechs" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/cadra-in/",
  },
  { Social: <FaWhatsapp />, link: "/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/_cadra_/" },
  { Social: <FaEnvelope />, link: "mailto:info@cadra.in" },
];
class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Ready To Do This</span>
                    <h2>
                      Let's get <br /> to work
                    </h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>Contact Us</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Quick Link</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="/about">About</a>
                          </li>
                          <li>
                            <a href="/service">Services</a>
                          </li>
                          <li>
                            <a href="/join-us">Let's Talk</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Say Hello</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="tel:+918754280955">+91 87542 80955</a>
                          </li>
                          <li>
                            <a href="mailto:info@cadra.in">info@cadra.in</a>
                          </li>
                        </ul>

                        <div
                          className="social-share-inner"
                          style={{ marginTop: "15px" }}
                        >
                          <ul className="social-share rn-md-size d-flex justify-content-start liststyle">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © 2022 Hand Crafted by{" "}
                          <a href="/"> CADRA.</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
