import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";

class SliderOne extends Component {
  render() {
    return (
      <div className="slider-activation">
        {/* Start Single Slide */}
        <div
          className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1"
          data-black-overlay="6"
        >
          <div
            style={{ marginBottom: "150px" }}
            className="container position-relative"
          >
            <div className="row">
              <div className="col-xl-12">
                <div className="inner">
                  <h1
                    style={{ marginTop: "100px" }}
                    className="title theme-gradient"
                  >
                    Crew of Algorithmic Developers & Research Associates.{" "}
                  </h1>
                </div>
              </div>
              {/* <div className="col-xl-4">
                <div className="inner">
                  <Spline scene="https://prod.spline.design/zijR8dif6Kg3gTyC/scene.splinecode" />
                </div>
              </div> */}
            </div>
            {/* Start Service Area */}
            <div className="service-wrapper service-white">
              <ServiceOne />
            </div>
            {/* End Service Area */}
          </div>
        </div>
        {/* End Single Slide */}
      </div>
    );
  }
}
export default SliderOne;

/*
  Auto-generated by Spline
*/
