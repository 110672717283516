import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from "google-map-react";
import ContactTwo from "../elements/contact/ContactTwo";

import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => (
  <div>
    <img
      width="35px"
      src={"/assets/images/marker.png"}
      onClick={() => {
        window.open("https://goo.gl/maps/1WP1fjAxR57SWEdbA", "_blank");
      }}
    />
    {text}
  </div>
);

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 11.000828860555158,
      lng: 77.03169721633817,
    },
    zoom: 17,
  };

  render() {
    return (
      <React.Fragment>
        <div className="active-dark">
          <PageHelmet pageTitle="Contact | CADRA" />

          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />

          {/* Start Breadcrump Area */}
          <div
            className="rservice-area ptb--120 bg_color--4"
            data-black-overlay="6"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">Contact With Us</h2>
                    <p>
                      Have an idea? Great! Tell us your draft and we will craft.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Contact Top Area  */}
          <div className="rn-contact-top-area ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div
                    className="rn-address"
                    style={{ backgroundColor: "#222" }}
                  >
                    <div className="icon">
                      <FiHeadphones />
                    </div>
                    <div className="inner">
                      <h4 className="title">Contact With Phone Number</h4>
                      <p>
                        <a href="tel:+91 87542 80955">+91 87542 80955</a>
                      </p>
                      {/* <p>
                        <a href="tel:+856 325 652 984">+856 325 652 984</a>
                      </p> */}
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                  <div
                    className="rn-address"
                    style={{ backgroundColor: "#222" }}
                  >
                    <div className="icon">
                      <FiMail />
                    </div>
                    <div className="inner">
                      <h4 className="title">Email Address</h4>
                      <p>
                        <a
                          href="mailto:info@cadra.com"
                          style={{ color: "#fff" }}
                        >
                          info@cadra.in
                        </a>
                      </p>
                      {/* <p>
                        <a href="mailto:example@gmail.com">admin@cadra.in</a>
                      </p> */}
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                  <div
                    className="rn-address"
                    style={{ backgroundColor: "#222" }}
                  >
                    <div className="icon">
                      <FiMapPin />
                    </div>
                    <div className="inner">
                      <h4 className="title">Location</h4>
                      <p>501C, Trichy Road, Singanallur, Coimbatore - 641005</p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}
              </div>
            </div>
          </div>
          {/* End Contact Top Area  */}

          {/* Start Contact Page Area  */}
          <div className="rn-contact-page ptb--120 bg_color--1">
            <ContactTwo />
          </div>
          {/* End Contact Page Area  */}

          {/* Start Contact Map  */}
          <div className="rn-contact-map-area position-relative ptb--120 bg_color--1">
            <div style={{ height: "650px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDftylJ7z2saDwAAcq2CeX6j_RXqWmlhHI",
                }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
              >
                <AnyReactComponent
                  lat={11.000828860555158}
                  lng={77.03169721633817}
                  text="CADRA"
                />
              </GoogleMapReact>
            </div>
          </div>
          {/* End Contact Map  */}

          {/* Start Brand Area */}
          {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <BrandTwo />
                </div>
              </div>
            </div>
          </div> */}
          {/* End Brand Area */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default Contact;
