import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Lottie from "lottie-react-web";

const ServiceList = [
  {
    icon: "02",
    title: "Web Development",
    description:
      "We develop High-performance websites utilizing rapid application frameworks, that are aligned to strategic business objectives.",
  },
  {
    icon: "05",
    title: "App Development",
    description:
      "Whether looking to create a mobile application for smartphones we have your organization covered no matter the platform or device.",
  },
  {
    icon: "01",
    title: "Graphic Design",
    description:
      "Transcend limits with creative designs for your brand with the wide range of designs and illustrations we offer.",
  },
  {
    icon: "06",
    title: "Business Analytics",
    description:
      "Why don’t you check out how we give hand in to fix solutions to meet your business goals by framework-ing in orderly fashion?",
  },
  {
    icon: "03",
    title: "Data Analytics",
    description:
      "We bring off practical trends and conclusions on your info. How about fetching it in to see what your data has in?",
  },
  {
    icon: "04",
    title: "Data Dashboard",
    description:
      "Isn’t it easy to sort effects when your data is in a delineated way? We’ll help you to track down your data phase distinctly.",
  },
];
class Service extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="active-dark">
          <PageHelmet pageTitle="Service | CADRA" />
          <Header
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
          />

          {/* Start Breadcrump Area */}
          <Breadcrumb title={"Service"} />
          {/* End Breadcrump Area */}

          {/* Start Service Area */}
          <div className="service-area ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {/* <div className="section-title text-center mb--30">
                    <h2>Digital Marketing</h2>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, <br /> but the majority have suffered
                      alteration.
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="row service-one-wrapper">
                {ServiceList.map((val, i) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                    key={i}
                  >
                    {/* <a href="/service"> */}
                    <div className="service service__style--2">
                      <div className="icon">
                        {" "}
                        <Lottie
                          options={{
                            animationData: require(`../../public/assets/json/lottie/icon-${val.icon}.json`),
                            loop: true,
                          }}
                          speed="0.6"
                          width="130px"
                          height="130px"
                        />
                      </div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                    {/* </a> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* End Service Area */}

          {/* Start Service Area */}
          {/* <div className="service-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Strategy</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center" href="/service-details">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div> */}
          {/* End Service Area */}

          {/* Start Service Area */}
          {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Creative Agency</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a href="/service-details">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div> */}
          {/* End Service Area */}

          {/* Start Service Area */}
          {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Development</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a className="text-center" href="/service-details">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div> */}
          {/* End Service Area */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default Service;
