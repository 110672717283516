import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ContactThree from "../elements/contact/ContactThree";

const ContactForm = () => {
  return (
    <div className="active-dark">
      <PageHelmet pageTitle="Join Us | CADRA" />

      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Join Us"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Contact Form  */}
        <div className="rn-contact-form-area ptb--120 bg_color--1">
          <ContactThree
            contactTitle="Contact Us"
            contactImages="/assets/images/about/about-6.jpg"
          />
        </div>
        {/* Start Contact Form  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </div>
  );
};

export default ContactForm;
